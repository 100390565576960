import React from 'react'
import Currency from '../Currency'
import OrderStatus from '../OrderStatus'
import OrderPaymentStatus from '../OrderPaymentStatus'
import { toTimeStamp } from '../../../utils'

const RecentOrdersMobile = ({orders}) => {
  return (
    <div>
        {orders?.map((order, orderIndex) => (
            <div key={orderIndex} className='w-full rounded-md border border-gray-200 p-5 my-5 flex items-start gap-x-3'>
                <div className="w-[50px] flex flex-col justify-center items-center h-[50px] bg-gray-100 bg-opacity-50 rounded">
                    <p className="text-2xl font-courier-prime font-thin text-nadabake-purple">{order?.items?.length}</p>
                    <p className="text-xs -mt-[10px] text-gray-400">Items</p>
                </div>
                <div className='w-full'>
                    <p className='text-sm text-gray-600'>{order.alias}</p>
                    <span className="text-gray-400"><Currency amount={order.total} /></span>
                    <div className='flex items-center justify-between mt-3'>
                        <OrderStatus status={order.status} />
                        <OrderPaymentStatus status={order.paymentStatus} />
                    </div>
                    <p className='text-sm text-gray-600 mt-3'>{toTimeStamp(order.createdAt)}</p>
                    
                </div>
            </div>
        ))}
    </div>
  )
}

export default RecentOrdersMobile
import { FETCHING_STATS, FETCH_STATS, STATS_ERROR } from "../types"

const initialState = {
    fetchingStats: false,
    stats: null,
    statsError: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case FETCHING_STATS:
        return {
            ...state,
            fetchingStats:action.payload,
        }
        case FETCH_STATS:
        return{
            ...state,
            fetchingStats:false,
            statsError: null,
            stats: action.payload,
        }
        case STATS_ERROR:
        return{
            ...state,
            statsError: action.payload 
        }
        default: return state
    }

}
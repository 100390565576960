import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowWide from '../../components/elements/icons/ArrowWide'
import CircleIcon from '../../components/elements/icons/CircleIcon'
import MoneyIcon from '../../components/elements/icons/MoneyIcon'
import OrdersIcon from '../../components/elements/icons/OrdersIcon'
import UserLayout from '../../components/Layout/UserLayout'
import BarChart from '../../components/partials/BarChart'
import RecentOrders from '../../components/partials/dashboard/RecentOrders'
import ModalLayout from '../../components/Layout/ModalLayout'
// import DataImportModal from '../../components/partials/DataImportModal'
import InlinePreloader from '../../components/elements/InlinePreloader'
import { authHeader, storeDetails, baseUrl, userDetails } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { FETCHING_STATS, FETCH_STATS, STATS_ERROR } from '../../store/types'
import axios from 'axios'
import { fetchStores } from '../../store/actions/storeActions'
import PathArrowIcon from '../../components/elements/icons/PathArrowIcon'
import ChangeStore from '../../components/partials/dashboard/ChangeStore'

// const fs = require('fs');

const Dashboard = () => {
    const dispatch = useDispatch()
    const statsState = useSelector((state => state.stats))

    const [activeSession] = useState(false);
    const [displayChart, setDisplayChart] = useState(false);
    const [stats, setStats] = useState(null);
    // const store = await storeDetails()
    const [activeStore, setActiveStore] = useState(null);

    const fetchStats = useCallback(async (storeId) => {
        try {
            const headers = authHeader();
            const store = await storeDetails();
        
            let selectedStore = '';
            if(!storeId || storeId === '') {
                selectedStore = store.storeId
                setActiveStore(store)
            } else {
                selectedStore = storeId
            }
    
            dispatch({ type: FETCHING_STATS, payload: true });
        
            const response = await axios.get(`${baseUrl}/dashboard/stats/${selectedStore}`, { headers });
            setStats(response.data.data);
            updateChartData(response.data.data.transactionsSummary, 'daySummary');
        
            dispatch({ type: FETCH_STATS, payload: response.data.data });
        
        } catch (error) {
          console.error(error);
          dispatch({ type: STATS_ERROR, error });
        }
    }, [dispatch,]); // Memoize the function to prevent re-creating it on every render
    
    useEffect(() => {
        dispatch(fetchStores());
        fetchStats(); // Call the function without arguments to use the default storeId

        return () => {
            // Cleanup if necessary
        };
    }, [dispatch, fetchStats]); // Add fetchStats to the dependency array

    const [chartData, setChartData] = useState({});
    const [chartPeriod, setChartPeriod] = useState('daySummary');
    const updateChartData = (data, type) => {
        setChartPeriod(type)
        setDisplayChart(false)
        const amounts = []
        const labels = []

        data[type].forEach(item => {
            amounts.push(item.amount === 0 ? 0.0000001 : item.amount)
            if(type === 'daySummary') {
                labels.push(item.day)
            }
            if(type === 'weekSummary') {
                labels.push(item.week)
            }
            if(type === 'monthSummary') {
                labels.push(item.month)
            }
        })

        // if(amounts.every(num => num === 0))

        setChartData({
            amounts: amounts, labels
        })
        setTimeout(() => {
            setDisplayChart(true)
        }, 200);
    }

    const [changeStoreModalOpen, setChangeStoreModalOpen] = useState(false);

    return (
        <>
            {statsState.fetchingStats ? 
                <div className='w-full h-[200px] flex items-center justify-center'>
                    <InlinePreloader />
                </div>
             : <UserLayout pageTitle="Dashboard">
                {(sessionData) => <div className=''>
                    <div className='w-full xl:flex flex-row gap-x-8'>
                        <div className='w-full xl:w-9/12 border-b border-gray-200 pb-6 px-4 lg:px-12 mt-12'>
                            <div className='lg:flex items-center justify-between'>
                                <div className='flex items-center justify-center lg:justify-default gap-x-6 mb-4'>
                                    <h3 className='font-medium text-center lg:text-left text-2xl text-gray-400'>Welcome {userDetails().name?.split(' ')[0]}!</h3>
                                </div>
                                <div className='flex items-center justify-center lg:justify-default flex-row-reverse gap-x-4'>
                                    <button onClick={()=>{setChangeStoreModalOpen(true)}} className='rounded border border-blue-600 bg-gray-100 p-2 text-sm flex items-center justify-center gap-x-3 text-blue-600'>
                                        <PathArrowIcon className={`w-5 h-5 transform rotate-180`}/> Change Store
                                    </button>

                                    <p>Reports for <span className='font-[500] text-nadabake-purple'>{activeStore?.name}</span></p>


                                    {/* <button onClick={()=>{exportData()}} className='rounded border border-nadabake-purple bg-gray-100 p-3 text-sm flex items-center justify-center gap-x-3 text-nadabake-purple'>
                                        <ExportImportIcon className={`w-5 h-5 transform rotate-180`}/> Export data
                                    </button> */}
                                </div>
                            </div>

                            <div className='xl:flex flex-row gap-x-8 mt-2'>
                                <div className='relative w-full xl:w-4/12 flex gap-x-4 my-12 p-5'>
                                    <MoneyIcon className="w-12 h-12 bg-green-400 bg-opacity-10 text-green-600 rounded-lg p-3" />
                                    <div>
                                        <h3 className='font-courier-prime text-4xl text-gray-600'><span className='text-sm'>NGN </span>{stats?.metrics.thisMonthOrdersValue.toLocaleString()}</h3>
                                        <p className='text-sm mt-3'>From <span className='text-xl font-courier-prime text-gray-500'>{stats?.metrics.thisMonthOrdersCount.toLocaleString()}</span> orders this month </p>
                                        <p className='text-sm mt-1 text-green-600'>NGN {stats?.metrics.currentYearOrdersValue.toLocaleString()} total this year.</p>
                                        {/* {sessionData.permissions.includes('can_manage_orders') && <div className='mt-2 block'>
                                            <Link to="/user/orders" className='text-blue-600 text-sm'>See all orders</Link>
                                        </div>} */}
                                    </div>
                                </div>

                                <div className='relative w-full xl:w-4/12 flex gap-x-4 my-12 p-5'>
                                    <OrdersIcon className="w-14 h-12 bg-green-400 bg-opacity-10 text-green-600 rounded-lg p-3" />
                                    <div>
                                        <h3 className='font-courier-prime text-4xl text-gray-600'>{stats?.metrics.todayOrdersCount.toLocaleString()} <span className='text-sm mt-3 -ml-3'>Orders today.</span></h3>
                                        <p className='text-sm mt-3'><span className='text-xl font-courier-prime text-gray-500'>{stats?.metrics.todayOrdersCount.toLocaleString()}</span> orders completed today. <span className='text-xl font-courier-prime text-gray-500'>0</span> in progress</p>
                                        <p className='text-sm mt-1 text-green-600'>NGN {stats?.metrics.todayOrdersValue.toLocaleString()} current value.</p>

                                    </div>
                                </div>

                            </div>

                            <div className='w-full lg:flex items-center justify-between gap-x-4 p-5'>
                                    <div className='rounded-md border-opacity-10 border-mms-green bg-opacity-10 px-10 py-5 mb-3 relative'>
                                        {/* <ProductsIcon className={`w-16 h-16 text-mms-green text-opacity-20 absolute top-6 right-6`} /> */}
                                        <p className='mb-2 text-green-600'>Most sold item </p>
                                        <div className='flex items-center gap-x-4'>
                                            <div className='w-[50px] h-[50px] rounded-md bg-green-400 bg-opacity-20 flex items-center justify-center'>
                                                <ArrowWide className={`w-8 h-8 text-green-600 transform rotate-45`}/>
                                            </div>
                                            <div>
                                                <p className='text-md text-gray-500'>{stats?.metrics?.mostSoldItem.name}</p>
                                                <p className='text-sm'>{stats?.metrics?.mostSoldItem.quantity} sold</p>
                                                {/* <p className='text-sm'>N50,000 sold this week</p> */}
                                            </div>
                                        </div>
                                        {/* {sessionData.permissions.includes('can_manage_items') && <div className='mt-2 block'>
                                            <Link to="/user/items" className='text-nadabake-purple text-sm'>Go to items</Link>
                                        </div>} */}
                                    </div>

                                    <div className='rounded-md border-opacity-10 border-mms-red px-10 py-5 my-3 relative'>
                                        {/* <StoreIcon className={`w-16 h-16 text-mms-red text-opacity-20 absolute top-6 right-6`} /> */}
                                        <p className=' mb-2 text-mms-red'>{stats?.metrics?.lowStock?.length.toLocaleString()} - Low stock items </p>
                                        <div className='flex items-center gap-x-4'>
                                            <div className='w-[50px] h-[50px] rounded-md bg-mms-red bg-opacity-20 flex items-center justify-center'>
                                                <ArrowWide className={`w-8 h-8 transform rotate-[135deg] text-mms-red`}/>
                                            </div>
                                            <div>
                                                <p className='text-md text-gray-500'>{stats?.metrics?.lowStock[0]?.itemName} - {statsState?.stats?.metrics?.lowStock[0]?.variantName}</p>
                                                <p className='text-sm'>{stats?.metrics?.lowStock[0]?.currentStock.toLocaleString()} {stats?.metrics?.lowStock[0]?.saleUnit} remaining</p>
                                            </div>
                                        </div>
                                        {/* {sessionData.permissions.includes('can_manage_inventory') && <div className='mt-2 block'>
                                            <Link to="/user/inventory" className='text-nadabake-purple text-sm'>Go to inventory</Link>
                                        </div>} */}
                                    </div>

                                    <div className='rounded-md border-opacity-10 border-mms-red px-10 py-5 my-3 relative'>
                                        {/* <StoreIcon className={`w-16 h-16 text-mms-red text-opacity-20 absolute top-6 right-6`} /> */}
                                        <p className='mb-2 text-yellow-800'>{stats?.metrics?.closedUnpaidOrdersCount.toLocaleString()} closed orders unpaid </p>
                                        <div className='flex items-center gap-x-4'>
                                            <div className='w-[50px] h-[50px] rounded-md bg-yellow-600 bg-opacity-20 flex items-center justify-center'>
                                                <CircleIcon className={`w-8 h-8 text-yellow-800`}/>
                                            </div>
                                            <div>
                                                <p className='text-md text-gray-500'>N{stats?.metrics?.unpaidOrdersValue.toLocaleString()}</p>
                                                {/* <p className='text-md text-gray-500'>N23,450</p> */}
                                                <p className='text-sm'>unpaid across {stats?.metrics?.closedUnpaidOrdersCount} orders</p>
                                            </div>
                                        </div>
                                        {/* {sessionData.permissions.includes('can_manage_orders') && <div className='mt-2 block'>
                                            <Link to="/user/orders" className='text-nadabake-purple text-sm'>See all orders</Link>
                                        </div>} */}
                                    </div>

                                </div>

                            {sessionData.permissions.includes('can_manage_orders') && <div className='w-full xl:flex flex-row gap-x-4'>
                                {/* <Events /> */}
                                <div className='w-full p-5'>
                                    <div className='flex justify-between items-center mt-4 mb-4'>
                                        <h3 className='font-medium text-lg text-gray-400'>Recent Orders</h3>
                                        {activeSession?.doc?.user?.permissions?.includes('can_manage_menus') && <Link to={'/user/orders/new-order'} className='rounded bg-blue-700 px-3 py-2 text-white text-sm transition duration-200 hover:bg-blue-800'>Start a new Order</Link>}
                                    </div>
                                        {activeStore && <RecentOrders storeId={
                                            activeStore?.storeId
                                        } />}

                                    {/* {sessionData.permissions.includes('can_manage_orders') && <div className='mt-6 block'>
                                        <Link to="/user/orders" className='text-blue-600 text-sm'>See all orders</Link>
                                    </div>} */}
                                </div>
                                
                            </div>}
                        </div>

                        <div className='w-full xl:w-3/12 bg-gray-100 h-inherit xl:h-screen px-10 py-5 xl:fixed right-0 overflow-y-scroll'>
                            <>
                            <h3 className='font-medium text-lg text-gray-700'>Revenue Stats</h3>
                            <p className='text-sm mt-2 text-gray-500 mb-4'>This is your revenue performance for the selected time period across all revenue streams. <br />Use the dropdown to select time period</p>
                            <select value={chartPeriod} className='py-2 px-4 block w-full text-sm focus:border-gray-800 focus:outline-none border border-gray-400 font-outfit placeholder:font-outfit' onChange={(e)=>{updateChartData(statsState.stats.transactionsSummary, e.target.value)}}>
                                <option>
                                    -- select an option --
                                </option>
                                <option value='daySummary'>
                                    Daily
                                </option>
                                <option value='weekSummary'>
                                    Weekly
                                </option>
                                <option value='monthSummary'>
                                    Monthly
                                </option>
                            </select>

                            {displayChart && 
                            
                                <div className='' >
                                    <BarChart chartData={chartData} />
                                </div>
                            }
                            </>

                            <h3 className='font-medium text-lg text-gray-400'>Total transactions by channel</h3>
                            
                            <h3 className='font-medium text-md text-gray-400 mt-3'>Today</h3>
                            {statsState?.stats?.transactionsByChannel && Object.entries(statsState?.stats?.transactionsByChannel?.currentDay).map(([channel, amount], itemIndex) => (
                                <p key={itemIndex} className='font-medium text-md py-3 border-b border-gray-300 text-gray-600'><span className='text-gray-400 uppercase text-sm inline-block w-1/2'>{channel}:</span> ₦{amount.toLocaleString()}</p>
                            ))}
                            <h3 className='font-medium text-md text-gray-400 mt-3'>This week</h3>
                            {statsState?.stats?.transactionsByChannel && Object.entries(statsState?.stats?.transactionsByChannel?.currentWeek).map(([channel, amount], itemIndex) => (
                                <p key={itemIndex} className='font-medium text-md py-3 border-b border-gray-300 text-gray-600'><span className='text-gray-400 uppercase text-sm inline-block w-1/2'>{channel}:</span> ₦{amount.toLocaleString()}</p>
                            ))}
                            <h3 className='font-medium text-md text-gray-400 mt-3'>This month</h3>
                            {statsState?.stats?.transactionsByChannel && Object.entries(statsState?.stats?.transactionsByChannel?.currentMonth).map(([channel, amount], itemIndex) => (
                                <p key={itemIndex} className='font-medium text-md py-3 border-b border-gray-300 text-gray-600'><span className='text-gray-400 uppercase text-sm inline-block w-1/2'>{channel}:</span> ₦{amount.toLocaleString()}</p>
                            ))}

                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>

                </div>}
            </UserLayout>}

            <ModalLayout
                isOpen={changeStoreModalOpen} 
                closeModal={()=>{setChangeStoreModalOpen(false)}} 
                actionFunction={()=>{}} 
                dialogTitle='Change report context'
                maxWidthClass='max-w-md'
            >
                <ChangeStore returnStore={(store)=>{
                    setActiveStore({...store, ...{storeId: store._id}})
                    fetchStats(store._id)
                    setChangeStoreModalOpen(false)
                }} />
            </ModalLayout>
        </>
    )
}

export default Dashboard
import React, { useState } from 'react'
import SelectField from '../../elements/form/SelectField'
import FormButton from '../../elements/form/FormButton'
import { useSelector } from 'react-redux';

const ChangeStore = ({returnStore}) => {
    const [selectedStore, setSelectedStore] = useState(null);
    const storesState = useSelector((state => state.stores))
    const saveSelection = () => {
        returnStore(selectedStore)
    }
  return (
    <div>
        <h3 className='text-2xl mb-4'>Change Store</h3>
        <p className='text-black'>Please Select a store below to change reports context</p>

        <div className='w-full my-5'>
            <SelectField
                selectOptions={storesState?.stores?.stores}
                inputLabel="Select store"
                titleField="name"
                displayImage={false}
                imageField=""
                preSelected=''
                fieldId="account"
                hasError={false}
                // return id of accounts of the selected option
                returnFieldValue={(value) => { setSelectedStore(value) }}
            />
        </div>

        <div className='my-8 w-full'>
            <FormButton buttonLabel="Select store" buttonAction={()=>{saveSelection()}} processing={false} />
        </div>
    </div>
  )
}

export default ChangeStore
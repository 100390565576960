import React, { useEffect, useState } from 'react'
import { tableHeadersFields } from '../../../utils'
import DataTable from '../../elements/DataTable'
import Currency from '../Currency'
import CustomerSnippet from '../CustomerSnippet'
import OrderPaymentStatus from '../OrderPaymentStatus'
import OrderExpansion from '../orders/OrderExpansion'
import OrderStatus from '../OrderStatus'
import { useDispatch, useSelector } from 'react-redux'
import { pullData } from '../../../store/actions/syncActions'
import Loader from '../../elements/Loader'
import RecentOrdersMobile from './RecentOrdersMobile'
import OrderSummary from '../orders/OrderSummary'
import { fetchOrders } from '../../../store/actions/ordersActions'

const RecentOrders = ({storeId}) => {

  const [orders, setOrders] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch()
  const statsState = useSelector((state => state.stats))
  const ordersState = useSelector((state => state.orders))
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  useEffect(() => {
    dispatch(fetchOrders('', storeId, page, perPage))

    return () => {
    
    };
  }, [refresh]);

  const columnWidths = {
    // id: "w-full lg:w-1/12",
    orderAlias: "w-full lg:w-3/12",
    sourceMenu: "w-full lg:w-2/12",
    items: "w-full lg:w-2/12",
    status: "w-full lg:w-2/12",
    value: "w-full lg:w-2/12",
    payment: "w-full lg:w-1/12",
}

const cleanupData = (dataSet) => {
    const data = []
    dataSet?.forEach((item, itemIndex) => {
    data.push(
        {
            orderAlias: <OrderSummary item={item} />,
            sourceMenu: item.sourceMenu?.name,
            items: `${item.items?.length || 0} items in order`,
            status: <OrderStatus status={item.status} />,
            value: <Currency amount={item.total || 0}/>,
            payment: <OrderPaymentStatus status={item.paymentStatus} />,
        },
    )
    })

    return data
}

const [rowOpen, setRowOpen] = useState(null)

const toggleRowOpen = (rowIndex) => {
    if(rowOpen === null) {
        setRowOpen(rowIndex)
    } else {
        setRowOpen(null)
    }
} 

const tableOptions = {
    selectable: false,
    expandable: true,
    clickableRows: true,
    rowAction: (value)=>{toggleRowOpen(value)}
}

  return (
    <div className='w-full' style={{minHeight: '300px', height: 'inherit'}}>
      {!ordersState.fetchingOrders ? 
        ordersState.orders?.orders?.length > 0 ? <>
          <span className='hidden lg:block'> 
            <DataTable
              tableHeaders={tableHeadersFields(cleanupData(ordersState.orders?.orders)[0]).headers} 
              tableData={cleanupData(ordersState.orders?.orders)} 
              columnWidths={columnWidths}
              columnDataStyles={{}}
              allFields={tableHeadersFields(cleanupData(ordersState.orders?.orders)[0]).fields}
              onSelectItems={()=>{}}
              tableOptions={tableOptions}
              // pagination={{
              //     perPage: 25, 
              //     currentPage: 1,
              //     totalItems: 476,
              // }}
              // changePage={()=>{}}
              // updatePerPage={()=>{}}
              expandedIndex={rowOpen || ''}
              expansion={<OrderExpansion orders={ordersState.orders?.orders} rowOpen={rowOpen} />}
            />
          </span>
          <span className='lg:hidden'>
            <RecentOrdersMobile orders={ordersState.orders?.orders?.slice(0, 5)} />
          </span>
        </>
        : 
        <div className='w-full text-center mt-5'><p className='text-gray-400'>No Orders yet for this store</p></div>
      :

      <Loader />
      }
    </div>
  )
}

export default RecentOrders
import React, { useEffect, useState } from 'react'
import EyeIcon from '../icons/EyeIcon'
import EyeOffIcon from '../icons/EyeOffIcon'

const TextField = ({
    requiredField,
    inputLabel, 
    fieldId, 
    inputType, 
    hasError, 
    returnFieldValue, 
    preloadValue, 
    disabled, 
    includeButton, 
    buttonLabel, 
    buttonAction,
    bgClass,
    autoFocus,
    maxLength,
    hidePasswordToggler
}) => {
    const [ isFocused, setIsFocused ] = useState(false)
    const [ fieldValue, setFieldValue ] = useState(preloadValue)
    const [ hiddenInput, setHiddenInput ] = useState(true)
    // const id = generateCode(12)

    // const [fieldId, setFieldId] = useState(id)

    const toggleHiddenInput = (e) => {
        e.preventDefault()
        setHiddenInput(!hiddenInput)
    }

    const focusField = () => {
        if(disabled) {return}
        setIsFocused(true)
        document.getElementById(fieldId).focus()
    }

    useEffect(() => {
    //   setFieldValue(preloadValue)
        if (autoFocus && autoFocus === true) {
            focusField()
        }

    }, [autoFocus, focusField])

    const setValue = (value) => {
        setFieldValue(value)
        returnFieldValue(value)
    }

    return (
        <div>
            <label 
                className={`text-sm lg:text-md cursor-text z-10 relative py-1 transition duration-200  
                ${hasError ? 'text-red-600' : 'text-gray-500'}`}
            >
             {requiredField && requiredField === true && <span className='text-red-600'>*</span>}   {inputLabel}
            </label>

            <input 
                id={fieldId} 
                type="text"
                maxLength={maxLength}
                className={`py-2 px-4 block w-full focus:border-gray-800 focus:outline-none border border-gray-400 font-outfit placeholder:font-outfit`} 
                onFocus={()=>{setIsFocused(true)}} 
                onChange={(e)=>{setValue(e.target.value)}}
                value={fieldValue}
                disabled={disabled}
            />

        </div>
    )
}

export default TextField
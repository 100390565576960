import { CREATED_STORE, CREATING_STORE, GETTING_STORE, GETTING_STORES, GET_STORE, GET_STORES, STORE_ERROR, UPDATED_STORE, UPDATING_STORE } from "../types"

const initialState = {
    creatingStore: false,
    createdStore: null,
    storeError: null,
    gettingStoreDetails: false,
    store: null,
    gettingStores: false,
    stores: [],
    updatingStore: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_STORE:
        return {
            ...state,
            creatingStore:action.payload,
            // fetchingMembers:false
        }
        case CREATED_STORE:
        return{
            ...state,
            creatingStore:false,
            storeError: null,
            createdStore: action.payload,
        }
        case UPDATING_STORE:
        return {
            ...state,
            updatingStore:action.payload,
            // fetchingMembers:false
        }
        case UPDATED_STORE:
        return{
            ...state,
            updatingStore:false,
            storeError: null,
            updatedStore: action.payload,
        }
        case GETTING_STORE:
        return {
            ...state,
            gettingStoreDetails: action.payload,
        }
        case GET_STORE:
        return{
            ...state,
            gettingStore:false,
            store: action.payload,
        }
        case GETTING_STORES:
        return {
            ...state,
            gettingStores: action.payload,
        }
        case GET_STORES:
        return{
            ...state,
            gettingStores:false,
            stores: action.payload,
        }
        case STORE_ERROR:
        return{
            ...state,
            storeError: action.payload 
        }
        default: return state
    }

}
// Datasync
export const PUSHING_DATA = 'PUSHING_DATA';
export const PULLING_DATA = 'PULLING_DATA';
export const PUSHED_DATA = 'PUSHED_DATA';
export const PULLED_DATA = 'PULLED_DATA';
export const DELETING_DATA = 'DELETING_DATA';
export const DELETED_DATA = 'DELETED_DATA';
export const DATASYNC_ERROR = 'DATASYNC_ERROR';
export const TOTAL_ITEMS = 'TOTAL_ITEMS';

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_ITEMS = 'GET_ITEMS'
export const GET_ITEM = 'GET_ITEM'
export const GET_MENUS = 'GET_MENUS'
export const GET_MENU = 'GET_MENU'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_ORDER = 'GET_ORDER'
export const GET_STOCK_HISTORY = 'GET_STOCK_HISTORY'


export const FETCHING_ORDERS = 'FETCHING_ORDERS'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const CREATING_ORDER = 'CREATING_ORDER'
export const CREATED_ORDER = 'CREATED_ORDER'
export const UPDATING_ORDER = 'UPDATING_ORDER'
export const UPDATED_ORDER = 'UPDATED_ORDER'
export const DELETING_ORDER = 'DELETING_ORDER'
export const DELETED_ORDER = 'DELETED_ORDER'
export const ORDERS_ERROR = 'ORDERS_ERROR'


// Dashboard Stats
export const FETCHING_STATS = 'FETCHING_STATS';
export const FETCH_STATS = 'FETCH_STATS';
export const STATS_ERROR = 'STATS_ERROR';

// Users
export const CREATING_USER = 'CREATING_USER';
export const CREATED_USER = 'CREATED_USER';
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATED_USER = 'UPDATED_USER';
export const DELETING_USER = 'DELETING_USER';
export const DELETED_USER = 'DELETED_USER';
export const USER_ERROR = 'USER_ERROR';
export const GETTING_USER_PROFILE = 'GETTING_USER_PROFILE'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GETTING_USERS = 'GETTING_USERS'
export const GET_USERS = 'GET_USERS'

// store
export const CREATING_STORE = 'CREATING_STORE';
export const CREATED_STORE = 'CREATED_STORE';
export const GETTING_STORE = 'GETTING_STORE';
export const GET_STORE = 'GET_STORE';
export const GETTING_STORES = 'GETTING_STORES';
export const GET_STORES = 'GET_STORES';
export const UPDATING_STORE = 'UPDATING_STORE';
export const UPDATED_STORE = 'UPDATED_STORE';
export const STORE_ERROR = 'STORE_ERROR';

// settings
export const UPDATING_SETTINGS = 'UPDATING_SETTINGS';
export const UPDATED_SETTINGS = 'UPDATED_SETTINGS';
export const FETCHING_SETTINGS = 'FETCHING_SETTINGS';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SETTINGS_ERROR = 'SETTINGS_ERROR';

// Errors
export const ERROR = 'ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Errors
export const SUCCESS = 'SUCCESS'
export const CLEAR_SUCCESS= 'SUCCESS'
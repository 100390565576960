import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TextField from '../../components/elements/form/TextField';
import FormButton from '../../components/elements/form/FormButton';
import SuccessMessage from '../../components/elements/SuccessMessage'
import ErrorMessage from '../../components/elements/ErrorMessage'
import { ERROR } from '../../store/types';
import { authHeader, baseUrl } from '../../utils';
import axios from 'axios';
import PasswordField from '../../components/elements/form/PasswordField';
import { useDispatch } from 'react-redux';

const SetPassword = () => {
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const invalidateSession = async () => {
    //   console.log(activeSession)
    //   try {
    //     let session = await db.get(activeSession.id);
    //     session.active = false
    //     await db.put(session)
    //     navigate('/')
    //   } catch (error) {
    //     console.log('Error invalidating session: ', error)
    //   }
    // }
    
    // const passwordsMatch = (userTempPassword) => {
    //     return oldPassword === atob(userTempPassword)
    // }

    // const [activeSession, setActiveSession] = useState({});
    // const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // const fetchDocuments = async () => {
        //     try {
        //         const result = await db.allDocs({ include_docs: true }); 
        //         const session = result.rows.find((document)=>{return (document.doc.document === 'session' && document.doc.active === true)})
    
        //         setActiveSession(session)
    
        //         setTimeout(() => {
        //             setLoaded(true)
        //         }, 100);
        //     } catch (error) {
        //         console.error('Error fetching documents:', error);
        //     }
        // };
    
        // fetchDocuments();
    
    }, []);

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
    
        if(!oldPassword || oldPassword === '') {
            errors.oldPassword = true
        }
    
        if(!newPassword || newPassword === '') {
            errors.newPassword = true
        }
            
        setValidationErrors(errors)
        return errors
    }

    const [updateSuccess, setUpdateSuccess] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [processing, setProcessing] = useState(false);

    const setUserPassword = async () => {
        if (Object.values(validateForm()).includes(true)) {
            return
        }

        try {
            const headers = {headers: authHeader()}
            console.log('headers: ', headers)
            const payload = {
                password: oldPassword,
                newPassword: newPassword
            }
    
            setProcessing(true)
    
            await axios.post(`${baseUrl}/user/change-password`, payload, headers)
            setUpdateSuccess('Password updated successfully! logging you in...')
            updateUserProfile()
            
        } catch (error) {
            console.error('Error setting password:', error);
            dispatch({
                type: ERROR,
                error
            })
        }
    }

    const updateUserProfile = async () => {
        if (Object.values(validateForm()).includes(true)) {
            return
        }

        try {
            const headers = {headers: authHeader()}
            console.log('headers: ', headers)
            const payload = {
                passwordChanged: true
            }
    
            setProcessing(true)
    
            await axios.put(`${baseUrl}/user/profile`, payload, headers)
            navigate('/user')
            
        } catch (error) {
            console.error('Error creating session:', error);
            dispatch({
                type: ERROR,
                error
            })
        }
    }


    return (
        <>
            {updateSuccess && updateSuccess !== '' && <SuccessMessage message={updateSuccess} dismissHandler={()=>{setUpdateSuccess(null)}} />}
            {updateError && updateError !== '' && <ErrorMessage message={updateError} dismissHandler={()=>{setUpdateError(null)}} />}
            <div className='w-full min-h-screen h-inherit bg-blue-100 bg-opacity-50 pt-12'>
                <div className='w-10/12 md:w-8/12 xl:w-6/12 2xl:w-4/12 mx-auto px-12'>
                    <div className='bg-white rounded-md p-8 lg:p-12'>

                        <div className='w-full'>
                            <p className='text-gray800 font-medium'>Change your password</p>
                            <p className='text-sm text-gray-500 mt-1'>This is your first log in. Please provide your own password below. Fill in the temporary password along with the new password you were issued</p>
                            
                            <div className='my-8 w-full'>
                                <PasswordField
                                    inputLabel="Temporary password" 
                                    fieldId="password-old" 
                                    inputType="password" 
                                    preloadValue={''}
                                    hasError={validationErrors && validationErrors.oldPassword} 
                                    returnFieldValue={(value)=>{setOldPassword(value)}}
                                />
                            </div>
                            <div className='my-8 w-full'>
                                <PasswordField
                                    inputLabel="Choose a new Password" 
                                    fieldId="password-new" 
                                    inputType="password" 
                                    preloadValue={''}
                                    hasError={validationErrors && validationErrors.newPassword} 
                                    returnFieldValue={(value)=>{setNewPassword(value)}}
                                />
                            </div>
                        </div>

                        <div className='my-8 w-full'>
                            <FormButton buttonLabel="Set your password" buttonAction={()=>{setUserPassword()}} processing={processing} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetPassword
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_ERROR } from '../../store/types';
import CloseIconCircled from './icons/CloseIconCircled';
import ErrorMessage from './ErrorMessage';
// import LoginModal from './LoginModal';

const ErrorNotifier = () => {
    const dispatch = useDispatch();
    const error = useSelector(state => state?.errors?.error);
    const dismissHandler = () => {
        dispatch( {
            type: CLEAR_ERROR
        })
    }

    if (!error) return null;

    if(error && error.statusCode !== 401) {
        console.log('error notifier: ', error)
        setTimeout(() => {
            dismissHandler()
        }, 5000);
        
        return (
            // <div className='fixed top-5 left-5 mt-5 rounded-md text-white p-4 bg-red-700 shadow-lg shadow-red-500/30 transition duration-200  animate__animated animate__fadeIn'  style={{zIndex: '999'}}>
            //     <button className='absolute -right-8 -top-8' onClick={()=>{dismissHandler()}}>
            //         <CloseIconCircled className={`w-8 h-8 text-red-500`} />
            //     </button>
            //     <p className='font-medium text-md'>Sorry,something went wrong</p>
            //     <p className='text-sm text-white'>
            //         {error.message}
            //     </p>

            // </div>

            <ErrorMessage message={error.message} dismissHandler={()=>{dismissHandler()}} />
        )
    }

}

export default ErrorNotifier

import React from 'react'
import { CLEAR_SUCCESS } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import CloseIconCircled from './icons/CloseIconCircled';
import SuccessMessage from './SuccessMessage';

const SuccessNotifier = () => {
    const dispatch = useDispatch();
    const success = useSelector(state => state.success);

    const dismissHandler = () => {
        dispatch( {
            type: CLEAR_SUCCESS,
            payload: null
        })
    }

    if (!success.message || success.message === null) return null;

    if(success ) {
        setTimeout(() => {
            dismissHandler()
        }, 10000);
        
        return (
            // <div className='fixed top-5 left-5 mt-5 rounded-md text-white p-4 bg-green-700 shadow-lg shadow-green-500/30 transition duration-200  animate__animated animate__fadeIn'  style={{zIndex: '999'}}>
            //     <button className='absolute -right-8 -top-8' onClick={()=>{dismissHandler()}}>
            //         <CloseIconCircled className={`w-8 h-8 text-green-500`} />
            //     </button>
            //     <p className='font-medium text-md'>Success</p>
            //     <p className='text-sm text-white'>
            //         {success.message}
            //     </p>

            // </div>
            <SuccessMessage message={success.message} dismissHandler={()=>{dismissHandler()}} />
        )
    }

}

export default SuccessNotifier
import { CREATED_ORDER, CREATING_ORDER, DELETED_ORDER, DELETING_ORDER, FETCHING_ORDERS, FETCH_ORDERS, ORDERS_ERROR, UPDATED_ORDER, UPDATING_ORDER } from "../types"

const initialState = {
    creatingOrder: false,
    createdOrder: null,
    deletingOrder: false,
    deletedOrder: null,
    updatingOrder: false,
    updatedOrder: null,
    fetchingOrders: false,
    orders: null,
    ordersError: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_ORDER:
        return {
            ...state,
            creatingOrder: action.payload,
            // fetchingMembers:false
        }
        case CREATED_ORDER:
        return {
            ...state,
            creatingOrder: false,
            createdOrder: action.payload
        }
        case FETCHING_ORDERS:
        return {
            ...state,
            fetchingOrders: action.payload,
        }
        case FETCH_ORDERS:
        return{
            ...state,
            fetchingOrders:false,
            orders: action.payload,
        }
        case UPDATING_ORDER:
        return {
            ...state,
            updatingOrder: action.payload,
        }
        case UPDATED_ORDER:
        return{
            ...state,
            updatingOrder: false,
            updatedOrder: action.payload,
        }
        case DELETING_ORDER:
        return {
            ...state,
            deletingOrder: action.payload,
        }
        case DELETED_ORDER:
        return{
            ...state,
            deletingOrder: false,
            deletedOrder: action.payload,
        }
        case ORDERS_ERROR:
        return{
            ...state,
            creatingOrder: false,
            fetchingOrders: false,
            updatingOrder: false,
            ordersError: action.payload 
        }
        default: return state
    }

}
import { DATASYNC_ERROR, DELETED_DATA, DELETING_DATA, GET_CATEGORIES, GET_ITEM, GET_ITEMS, GET_MENU, GET_MENUS, GET_ORDERS, GET_PAYMENTS, GET_STOCK_HISTORY, PULLED_DATA, PULLING_DATA, PUSHED_DATA, PUSHING_DATA, TOTAL_ITEMS } from "../types"

const initialState = {
    pushingData: false,
    pullingData: false,
    dataSyncError: null,
    pushedData: null,
    pulledData: null,
    deletingData: false,
    deletedData: null,
    categories: [],
    items: [],
    itemDetails: null,
    menus: [],
    menuDetails: null,
    total: 0,
    orders: [],
    payments: [],
    stockHistory: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case PULLING_DATA:
        return {
            ...state,
            pullingData:action.payload,
            // fetchingMembers:false
        }
        case PULLED_DATA:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            pulledData: action.payload,
        }
        case PUSHING_DATA:
        return {
            ...state,
            pushingData:action.payload,
            // fetchingMembers:false
        }
        case PUSHED_DATA:
        return{
            ...state,
            pushingData:false,
            dataSyncError: null,
            pushedData: action.payload,
        }
        case DELETING_DATA:
        return {
            ...state,
            deletingData:action.payload,
            // fetchingMembers:false
        }
        case DELETED_DATA:
        return{
            ...state,
            deletingData:false,
            dataSyncError: null,
            deletedData: action.payload,
        }
        case TOTAL_ITEMS:
        return{
            ...state,
           total: action.payload,
        }
        case GET_CATEGORIES:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            categories: action.payload,
        }
        case GET_ITEMS:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            items: action.payload,
        }
        case GET_ORDERS:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            orders: action.payload,
        }
        case GET_PAYMENTS:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            payments: action.payload,
        }
        case GET_ITEM:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            itemDetails: action.payload,
        }
        case GET_MENUS:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            menus: action.payload,
        }
        case GET_STOCK_HISTORY:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            stockHistory: action.payload,
        }
        case GET_MENU:
        return{
            ...state,
            pullingData:false,
            dataSyncError: null,
            menuDetails: action.payload,
        }
        case DATASYNC_ERROR:
        return{
            ...state,
            pullingData:false,
            pushingData:false,
            pushedData: null,
            pulledData: null,
            dataSyncError: action.payload 
        }
        default: return state
    }

}
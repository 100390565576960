import React from 'react'

const Currency = ({amount}) => {
  return (
    <div className='flex gap-x-1'>
        <span className='text-xs mt-1'>₦</span>
        <span className='text-lg font-medium'>{(amount).toLocaleString()}</span></div>
  )
}

export default Currency
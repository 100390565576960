import React, { useState } from 'react'
import PasswordField from '../../elements/form/PasswordField'
import axios from 'axios';
import { authHeader, baseUrl } from '../../../utils';
import { ERROR, SUCCESS } from '../../../store/types';
import FormButton from '../../elements/form/FormButton';
import { useDispatch } from 'react-redux';

const ChangePassword = ({closeModal}) => {
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch()
    const validateForm = () => {
        let errors = {}
    
        if(!oldPassword || oldPassword === '') {
            errors.oldPassword = true
        }
    
        if(!newPassword || newPassword === '') {
            errors.newPassword = true
        }
            
        setValidationErrors(errors)
        return errors
    }

    const [processing, setProcessing] = useState(false);

    const setUserPassword = async () => {
        if (Object.values(validateForm()).includes(true)) {
            return
        }

        try {
            const headers = {headers: authHeader()}
            console.log('headers: ', headers)
            const payload = {
                password: oldPassword,
                newPassword: newPassword
            }
    
            setProcessing(true)
    
            await axios.post(`${baseUrl}/user/change-password`, payload, headers)

            dispatch({
                type: SUCCESS,
                payload: 'Password changed successfully!'
            })
            closeModal()

            
        } catch (error) {
            console.error('Error creating session:', error);
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }

    return (
        <div className='bg-white rounded-md p-8 lg:p-12'>
            <div className='w-full'>

                <p className='text-sm text-gray-500 mt-1'>Fill in the your current password along with your new password below</p>
                
                <div className='my-8 w-full'>
                    <PasswordField
                        inputLabel="Current password" 
                        fieldId="password-old" 
                        inputType="password" 
                        preloadValue={''}
                        hasError={validationErrors && validationErrors.oldPassword} 
                        returnFieldValue={(value)=>{setOldPassword(value)}}
                    />
                </div>
                <div className='my-8 w-full'>
                    <PasswordField
                        inputLabel="Choose a new Password" 
                        fieldId="password-new" 
                        inputType="password" 
                        preloadValue={''}
                        hasError={validationErrors && validationErrors.newPassword} 
                        returnFieldValue={(value)=>{setNewPassword(value)}}
                    />
                </div>
            </div>

            <div className='my-8 w-full'>
                <FormButton buttonLabel="Set your password" buttonAction={()=>{setUserPassword()}} processing={processing} />
            </div>
        </div>
    )
}

export default ChangePassword
import './App.css';
import React from 'react';
import './index.css';
import {
  Routes,
  Route,
  Navigate 
} from 'react-router-dom';
import Login from './pages/Login';
import ScrollToTop from './components/Layout/ScrollToTop';
import User from './pages/User';
import Dashboard from './pages/user/Dashboard';

import { Provider } from 'react-redux';
import store from "./store/store";
import ErrorNotifier from './components/elements/ErrorNotifier';

import SuccessNotifier from './components/elements/SuccessNotifier';
import SetPassword from './pages/user/SetPassword';
import Profile from './pages/user/Profile';


const App = () => {
    return (
        <Provider store={store}>
            <ErrorNotifier />
            <SuccessNotifier />
            <main>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" exact element={<Login />} />
                        <Route path="/user" element={<User />}>
                            <Route path="/user/" element={<Navigate replace to="/user/dashboard" />} />
                            <Route path="/user/dashboard" element={<Dashboard />} />

                            <Route path="/user/change-password" element={<SetPassword />} />
                            <Route path="/user/profile" element={<Profile />} />
                        </Route>
                    </Routes>
                </ScrollToTop>
            </main>
        </Provider>
    );

};

export default App;

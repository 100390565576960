import React, { useEffect, useState } from 'react'
import UserLayout from '../../components/Layout/UserLayout'
import TextField from '../../components/elements/form/TextField';
import FormButton from '../../components/elements/form/FormButton';
import { userDetails, baseUrl, authHeader } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SUCCESS } from '../../store/types';
import InlinePreloader from '../../components/elements/InlinePreloader';
import axios from 'axios';
import { updateUserProfile } from '../../store/actions/userActions';
import ModalLayout from '../../components/Layout/ModalLayout';
import ChangePassword from '../../components/partials/users/ChangePassword';

const Profile = ({activeSession}) => {
    const [administratorDetails, setAdministratorDetails] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [activeUserPermissions, setActiveUserPermissions] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [loadingUserProfile, setLoadingUserProfile] = useState(true);
    const dispatch = useDispatch()
    const usersState = useSelector((state => state.users))

    const allPermissions = [
        {
            label: "Can manage users",
            value: "can_manage_users",
            selected: false
        },
        {
            label: "Can manage transactions",
            value: "can_manage_transactions",
            selected: false
        },
        {
            label: "Can manage orders",
            value: "can_manage_orders",
            selected: false
        },
        {
            label: "Can manage inventory",
            value: "can_manage_inventory",
            selected: false
        },
        {
            label: "Can manage items",
            value: "can_manage_items",
            selected: false
        },
        {
            label: "Can manage menus",
            value: "can_manage_menus",
            selected: false
        },
        {
            label: "Can manage store",
            value: "can_manage_store",
            selected: false
        },
        {
            label: "Can manage reports",
            value: "can_manage_reports",
            selected: false
        },
    ]

    useEffect(() => {
        const userPermissions = (profilePermissions) => {
            let permissionsAvailable = []
            allPermissions.forEach((permission) => {
                permissionsAvailable.push(
                    {
                        label: permission.label,
                        value: permission.value,
                        selected: profilePermissions.includes(permission.value) ? true : false
                    }
                )
            })
            return permissionsAvailable
        }

        const fetchUser = async () => {
            try{
                const headers = authHeader()
           
                const response = await axios.get(`${baseUrl}/user/profile`, { headers })
                setAdministratorDetails(response.data.data)
                setActiveUserPermissions(userPermissions(response.data.data.permissions))
                setLoadingUserProfile(false)
                
            }
            catch(error){
                console.log(error)
                dispatch({
                    type: ERROR,
                    error
                })
            }
        };

        fetchUser()

        if(usersState.updatedUser !== null) {
            dispatch({
                type: SUCCESS,
                payload: 'Profile updated successfully!'
            })
        }
        
        return () => {
            
        };
    }, [usersState.updatedUser]);

    const validateForm = () => {
        let errors = {}
    
        if(!administratorDetails.name || administratorDetails.name === '') {
            errors.adminName = true
        }
        
        if(!administratorDetails.email || administratorDetails.email === '') {
            errors.email = true
        }
        
        if(!administratorDetails.phone || administratorDetails.phone === '') {
            errors.phone = true
        }
        
        if(!administratorDetails.username || administratorDetails.username === '') {
            errors.username = true
        }
            
        setValidationErrors(errors)
        return errors
    }

    const updateUser = () => {
        if (Object.values(validateForm()).includes(true)) {
            return
        }

        const userPermissions = activeUserPermissions
        .filter(permission => permission.selected === true)
        .map(permission => permission.value);


        const userUpdatePayload = {...administratorDetails, ...{
            permissions: userPermissions
        }}

        delete userUpdatePayload.userType
        delete userUpdatePayload.createdAt
        delete userUpdatePayload.updatedAt
        delete userUpdatePayload.__v
        delete userUpdatePayload._id
        console.log(userUpdatePayload)
        dispatch(updateUserProfile(userDetails()._id, userUpdatePayload))
    }

    const [changingPassword, setChangingPassword] = useState(false);

  return (
    <>
        <UserLayout pageTitle={`User profile`}>
        {sessionData => (
            loadingUserProfile ? 
                <div className='px-44 py-4 flex flex-row items-center justify-center gap-x-5 p-5 w-full text-xs text-center rounded-lg mt-8'>
                    <div className="w-6">
                        <InlinePreloader />
                    </div>
                </div>
            : 
            
                <div className='w-full min-h-screen h-inherit bg-blue-100 bg-opacity-50'>
                    {/* <div className={`w-full flex flex-row-reverse px-12 pt-5`}>
                        <button onClick={()=>{deleteItem()}} className={`text-red-400 text-sm flex gap-x-2 hover:text-red-800`}>
                            <TrashIcon className={`w-5 h-5`} />
                            Delete this user
                        </button> 
                    </div> */}
                    {/* {JSON.stringify(sessionData)} */}
                    <div className='w-full md:w-8/12 xl:w-6/12 mx-auto px-5 lg:px-12 pt-4 lg:pt-12 mb-12'>
                        <div className='bg-white rounded-md p-8 lg:p-12'>
        
                            <p className='text-lg text-gray-500 font-medium'>User Details</p>
                            {/* <p className='text-sm text-gray-500 mt-1'>Please provide details of the new user below</p> */}
        
                            <div className='my-8 w-full'>
                                <TextField
                                    inputLabel="Full name" 
                                    fieldId="full-name" 
                                    inputType="text" 
                                    preloadValue={ administratorDetails?.name || ''}
                                    hasError={validationErrors && validationErrors.adminName} 
                                    returnFieldValue={(value)=>{setAdministratorDetails({...administratorDetails, ...{name: value}})}}
                                    disabled={false}
                                />
                            </div>

                            <div className='my-8 w-full'>
                                <TextField
                                    inputLabel="ID Number" 
                                    fieldId="full-id-number" 
                                    inputType="text" 
                                    preloadValue={ administratorDetails?.idNumber || ''}
                                    hasError={validationErrors && validationErrors.idNumber} 
                                    returnFieldValue={(value)=>{setAdministratorDetails({...administratorDetails, ...{idNumber: value}})}}
                                    disabled={true}
                                />
                            </div>
        
                            <div className='my-8 w-full'>
                                <TextField
                                    inputLabel="Email Address" 
                                    fieldId="email-address" 
                                    inputType="text" 
                                    preloadValue={ administratorDetails?.email || ''}
                                    hasError={validationErrors && validationErrors.email} 
                                    returnFieldValue={(value)=>{setAdministratorDetails({...administratorDetails, ...{email: value}})}}
                                    disabled={false}
                                />
                            </div>
        
                            <div className='my-8 w-full'>
                                <TextField
                                    inputLabel="Phone number" 
                                    fieldId="phone-number" 
                                    inputType="text" 
                                    preloadValue={ administratorDetails?.phone || ''}
                                    hasError={validationErrors && validationErrors.phone} 
                                    returnFieldValue={(value)=>{setAdministratorDetails({...administratorDetails, ...{phone: value}})}}
                                    disabled={false}
                                />
                            </div>

                            <div className='my-8 w-full'>
                                <TextField
                                    inputLabel="Your username" 
                                    fieldId="username" 
                                    inputType="text" 
                                    preloadValue={ administratorDetails?.username || ''}
                                    hasError={validationErrors && validationErrors.username} 
                                    returnFieldValue={(value)=>{setAdministratorDetails({...administratorDetails, ...{username: value}})}}
                                    disabled={true}
                                />
                            </div>

                            <div className='my-8 w-full'>
                                <p className='text-gray-500 font-medium'>User Permissions</p>
                                <p className='text-sm text-gray-500 mt-1 mb-4'>Toggle permissions for the user. These will determine the areas the user will be able to access</p>
                                {activeUserPermissions?.map((permission, permissionIndex)=>(<div key={permissionIndex} className="w-full flex items-center gap-x-4 my-2">
                                    <input id={permission.value} type='checkbox' checked={permission.selected} disabled onChange={()=>{}} />
                                    <label htmlFor={permission.value} className='text-sm text-gray-500'>{permission.label}</label>
                                </div>))}
                            </div>

                            
                            <div className='my-8 w-full'>
                                <FormButton buttonLabel="Update profile" buttonAction={()=>{updateUser()}} processing={usersState.updatingUser} />
                            </div>
                            
                            <div className='w-full mt-8'>
                                <p className='text-gray-500 font-medium'>Password</p>
                                <p className='text-sm text-gray-500 mt-1'>Click the link below to set a new password of you need to change your password</p>
                                
                                <button onClick={()=>{setChangingPassword(true)}} className='font-medium transition text-sm block mt-6 duration-200 text-nadabake-purple hover:text-gray-400'>Change your password</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </UserLayout>
        <ModalLayout
            isOpen={changingPassword} 
            closeModal={()=>{setChangingPassword(false)}} 
            dialogTitle='Change your password'
            dialogIntro={``}
            maxWidthClass='max-w-xl'
        >
            <ChangePassword closeModal={()=>{setChangingPassword(false)}} />
        </ModalLayout>
    </>
  )
}

export default Profile
import axios from "axios"
import { authHeader, baseUrl } from "../../utils"
import { CREATED_STORE, CREATING_STORE, GETTING_STORES, GET_STORES, STATS_ERROR } from "../types"

export const createStore = () => async (payload, dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_STORE,
            payload: true
        })
        console.log('creating store in actions...')
        // const result = await db.allDocs({ include_docs: true });


        // const payload = {
        //     data: result.rows
        // }

        const response = await axios.post(`${baseUrl}/store`, payload, headers)
        
        dispatch({
            type: CREATED_STORE,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: STATS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const fetchStores = () => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: GETTING_STORES,
            payload: true
        })

        const response = await axios.get(`${baseUrl}/stores`, {headers})
        console.log(response.data.data)
        dispatch({
            type: GET_STORES,
            payload: response.data.data
        })
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: STATS_ERROR,
            // payload: error.response.data
            error
        })
    }
}
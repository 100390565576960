import axios from "axios"
import { authHeader, baseUrl, storeDetails, userDetails } from "../../utils"
import { CREATED_ORDER, CREATING_ORDER, DELETED_ORDER, DELETING_ORDER, FETCHING_ORDERS, FETCH_ORDERS, ORDERS_ERROR, UPDATED_ORDER, UPDATING_ORDER } from "../types"

export const createOrder = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()
        const store = await storeDetails()

        dispatch({
            type: CREATING_ORDER,
            payload: true
        })
        console.log('creating order in actions...')
        console.log(userDetails())

        const response = await axios.post(`${baseUrl}/orders`, {...payload, ...{store: store.storeId}}, { headers })
        
        dispatch({
            type: CREATED_ORDER,
            payload: response.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: ORDERS_ERROR,
            error
        })
    }
}

export const fetchOrders = (filterString, storeId, page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()
        // const store = await storeDetails()

        let url = `${baseUrl}/orders/${storeId}?expand=items.item,sourceMenu`
        // let url = `${baseUrl}/orders/${store.storeId}?expand=sourceMenu`
        if(filterString && filterString !== '') {
            url += `${url.includes('?') ? '&' : '?'}${filterString}`
        }

        if(page && page!=='') {
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }

        if(perPage && perPage!=='') {
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch({
            type: FETCHING_ORDERS,
            payload: true
        })

        console.log('getting orders in actions...')

        const response = await axios.get(url, { headers })
        console.log('orders response... ', response.data.data)
        dispatch({
            type: FETCH_ORDERS,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: ORDERS_ERROR,
            error
        })
    }
}

export const updateOrder = (orderId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()
        const store = await storeDetails()

        dispatch({
            type: UPDATING_ORDER,
            payload: true
        })
        console.log('updating order in actions...')

        const response = await axios.patch(`${baseUrl}/order/${orderId}`, {...payload, ...{store: store.storeId}}, { headers })
        
        dispatch({
            type: UPDATED_ORDER,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: ORDERS_ERROR,
            error
        })
    }
}

export const deleteOrder = (orderId) => async (dispatch) => {    
    try{
        const headers = authHeader()
        const store = await storeDetails()

        dispatch({
            type: DELETING_ORDER,
            payload: true
        })
        console.log('deleting order in actions...')

        const response = await axios.delete(`${baseUrl}/orders/${orderId}`, { headers })
        
        dispatch({
            type: DELETED_ORDER,
            payload: response.data.data
        })
        
    }
    catch(error){
        console.log(error)
        dispatch({
            type: ORDERS_ERROR,
            error
        })
    }
}

export const clearCreatedOrder = () => async (dispatch) => { 
    dispatch({
        type: CREATED_ORDER,
        payload: null
    })
}

export const clearUpdatedOrder = () => async (dispatch) => { 
    dispatch({
        type: UPDATED_ORDER,
        payload: null
    })
}

export const clearDeletedOrder = () => async (dispatch) => { 
    dispatch({
        type: DELETED_ORDER,
        payload: null
    })
}
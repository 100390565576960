import React from 'react'

const OrderStatus = ({status}) => {
  return (
    <>
        {status === "IN_PROGRESS" &&
            <span className="px-2 py-1 rounded text-blue-600 font-medium bg-blue-400 bg-opacity-10 text-xs">
                {/* <Circle width={15} /> */}
                In progress
            </span>
        }
        {status === "COMPLETED" &&
            <span className="px-2 py-1 rounded text-green-600 font-medium bg-green-400 bg-opacity-10 block text-xs">
                {/* <CheckIcon /> */}
                Completed
            </span>
        }
        {status === "CANCELED" &&
            <span className="px-2 py-1 rounded text-red-600 bg-red-400 bg-opacity-10 block text-xs">
                {/* <CloseIcon width={22} /> */}
                Cancelled
            </span>
        }
    </>
  )
}

export default OrderStatus
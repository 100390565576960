import { combineReducers } from "redux";
import syncReducer from "./syncReducer";
import statsReducer from "./statsReducer";
import userReducer from "./userReducer";
import { errorReducer } from "./errorReducer";
import { successReducer } from "./successReducer";
import settingsReducer from "./settingsReducer";
import ordersReducer from "./ordersReducer";
import storeReducer from "./storeReducer";

const rootReducer = combineReducers({
    // Add reducers here
    syncData: syncReducer,
    stats: statsReducer,
    users: userReducer,
    errors: errorReducer,
    settings: settingsReducer,
    orders: ordersReducer,
    stores: storeReducer,
    success: successReducer
});

export default rootReducer;
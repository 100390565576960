import React, { useEffect, useState } from 'react'
import UserHeader from '../partials/UserHeader'
import UserSidebar from '../partials/UserSidebar'
import { useNavigate } from 'react-router-dom';
import { userDetails as user } from '../../utils'

const UserLayout = ({children, pageTitle, hideBackButton}) => {
    const [activeSession, setActiveSession] = useState({});
    const [businessDetails, setBusinessDetails] = useState({});
    const navigate = useNavigate()
  
    useEffect(() => {
  
    }, []);

    return (
        <>
            {/* {loaded &&  */}
            <div className='flex flex-row'>
                {/* <div className='w-16 fixed h-screen border-r border-gray-100 z-40 bg-white'>
                    <UserSidebar activeSession={activeSession} />
                </div> */}
                <div className='w-full'>
                    <div className='fixed h-16 flex items-center border-b px-4 lg:px-12 py-2 border-gray-100 w-full z-40 bg-white'>
                        <UserHeader 
                            pageTitle={pageTitle} 
                            // activeSession={activeSession} 
                            businessDetails={businessDetails} hideBackButton={hideBackButton} />
                    </div>
                    <div className='pt-16'>
                        {children(user())}
                        {/* {React.cloneElement(children, { activeSession })} */}
                    </div>
                </div>
            </div>
            {/* } */}
        </>
    )
}

export default UserLayout
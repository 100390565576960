import React from 'react'
// import Tooltip from '@mui/material/Tooltip'
import GlobeIcon from '../../../components/elements/icons/GlobeIcon'
import StoreFrontIcon from '../../../components/elements/icons/StoreFrontIcon'

const OrderSummary = ({item}) => {
  return (
    <div className='w-full flex items-start gap-x-2'>
        <div className='w-[40px] flex items-center justify-center h-[40px]'>
            {item.source === 'ONLINE' && 

                <div className='w-[30px] h-[30px] rounded-full bg-green-400 bg-opacity-10 flex items-center justify-center'>
                    <GlobeIcon className={`w-5 h-5 text-green-500`} />
                </div>

            }
            {item.source === 'ONSITE' && 

                <div className='w-[30px] h-[30px] rounded-full bg-nadabake-purple bg-opacity-10 flex items-center justify-center'>
                    <StoreFrontIcon className={`w-5 h-5 text-nadabake-purple text-opacity-40`} />
                </div>

            }
        </div>
        <div>
            <p className='font-medium'>{item.alias}</p>
            <p className='text-xs'>Date: {new Date(item?.createdAt).toDateString()} - {new Date(item?.createdAt).toLocaleTimeString()}</p>
        </div>
    </div>
  )
}

export default OrderSummary